/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: Roboto, "Helvetica Neue", sans-serif;  */
  /* background-color: rgba(179, 182, 183, 0.35) !important; */
  /* background-color: #eef0f0; */
  background-color: #fafafa;
}

/* body { */
  body section.mainview {
  /* font-family: arial, sans-serif !important; */
  font: 400 14px/20px arial, "Helvetica Neue", sans-serif;
}

.willscolor {
  color: #580000 !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 3% !important;
}

.wills-h1-font {
  font-size: 2.1rem !important;
  font-family: adobe-garamond-pro, serif !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

.wills-header-font {
  font-family: 'Cambria' !important;
  font-weight: 600 !important;
}

.wills-nav-font {
  font-family: adobe-garamond-pro, serif !important;
  font-weight: 700 !important;
}